import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as Sentry from '@sentry/browser';

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandler implements ErrorHandler {
  /**
   * @description
   *
   * Determines whether or not we should instantiate Sentry to capture and send events,
   * by current [environment's]{@link Environment} [production]{@link Environment.production} value
   */
  static readonly IS_ENABLED = environment.production;

  constructor() {
    /**
     * Initial Sentry to set context information or send manual events.
     */
    Sentry.init({
      dsn: 'https://1092ab27e4f54060ad627223a1461df7@sentry.io/2297042',
      environment: environment.name,
      enabled: SentryErrorHandler.IS_ENABLED
    });
  }

  /**
   * @description
   *
   * A hook for centralized exception handling.
   *
   * @param error Error
   */
  handleError(error: any): void {
    /**
     * Captures an exception event and sends it to Sentry
     */
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}
