import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

export class HttpErrorResponseApi extends HttpErrorResponse {
  constructor(init: {
    error?: { [key: string]: any };
    headers?: HttpHeaders;
    status?: number;
    statusText?: string;
    url?: string;
  }) {
    super(init);
  }
}
