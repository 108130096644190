import { Component, OnInit } from '@angular/core';
import { Toast } from '../../models/toast/toast';
import { ToasterService } from '../../services/toaster/toaster.service';
import {
  animate,
  sequence,
  style,
  transition,
  trigger
} from '@angular/animations';
import {
  faCheck,
  faExclamation,
  faTimes,
  IconDefinition
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition('* => void', [
        style({
          height: '*',
          opacity: '1',
          transform: 'translateX(0)',
          'margin-bottom': '*'
        }),
        sequence([
          animate(
            '.2s ease',
            style({
              height: '*',
              opacity: '0',
              transform: 'translateX(0)',
              'margin-bottom': '*'
            })
          ),
          animate(
            '.1s ease',
            style({
              height: '0',
              opacity: '0',
              transform: 'translateX(0)',
              'margin-bottom': 0
            })
          )
        ])
      ]),
      transition('void => *', [
        style({ height: '0', opacity: '0', transform: 'translateX(350px)' }),
        sequence([
          animate(
            '.1s ease',
            style({
              height: '*',
              opacity: '.2',
              transform: 'translateX(350px)'
            })
          ),
          animate(
            '.2s ease',
            style({
              height: '*',
              opacity: 1,
              transform: 'translateX(0)'
            })
          )
        ])
      ])
    ])
  ]
})
export class ToasterComponent implements OnInit {
  /**
   * List of toasts
   */
  toasts: Toast[] = [];

  faCheck: IconDefinition = faCheck;
  faExclamation: IconDefinition = faExclamation;
  faTimes: IconDefinition = faTimes;

  constructor(private toasterService: ToasterService) {}

  /**
   * @description
   *
   * A lifecycle hook that is called after Angular has initialized
   * all data-bound properties of a directive.
   */
  ngOnInit(): void {
    this.toasterService.toastSubject.subscribe((toast: Toast | null): void => {
      // If there was a toast
      if (toast) {
        this.toasts.push(toast);

        toast.timeout = window.setTimeout((): void => {
          this.toasts.map((item: Toast, index: number): void => {
            if (item.id === toast.id) {
              this.toasts.splice(index, 1);
            }
          });
        }, toast.delay);
      }
    });
  }

  /**
   * Clear toast timeout on mouse enter
   *
   * @param toastTimeout Toast timeout reference
   */
  onMouseEnter(toastTimeout: any): void {
    clearTimeout(toastTimeout);
  }

  /**
   * Set toaster timeout on mouse leave
   *
   * @param toast Toast
   * @param index Index of toast in toasts list
   */
  onMouseLeave(toast: Toast, index: number): void {
    toast.timeout = window.window.setTimeout((): void => {
      this.toasts.splice(index, 1);
    }, toast.delay);
  }

  /**
   * Close toast and remove it from list
   *
   * @param toast Toast
   * @param index Index of toast in toasts list
   */
  close(toast: Toast, index: number): void {
    if (toast.timeout) {
      clearTimeout(toast.timeout);
    }
    this.toasts.splice(index, 1);
  }
}
