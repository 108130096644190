import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { ParamsObject } from '@app/interfaces/params-object';
import { ApiResponse } from '@app/interfaces/api-response';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  /**
   * @description
   *
   * It stores a param key which is responsible for not adding 'bt_workspace' param to current API call's URL
   */
  static readonly WORKSPACE_NOT_REQUIRED_PARAM = 'workspace_not_required';

  /**
   * @description
   *
   * Current environment's API
   *
   * @see environment
   */
  readonly api: { v1: string; v2: string; v3: string } = environment.api;

  /**
   * @description
   *
   * Pusher APP key
   *
   * @see environment
   */
  readonly pusherAppKey: string = environment.pusher.appKey;

  /**
   * @description
   *
   * Current environment's Send Server Url
   *
   * @see environment
   */
  readonly sendServer: string = environment.sendServerUrl;

  constructor(private http: HttpClient) {}

  /**
   * Get endpoint
   *
   * @param endpoint Endpoint to get
   * @param params Object map of the HTTP params
   */
  getEndpoint<T>(
    endpoint: string,
    params?: ParamsObject
  ): Observable<ApiResponse<T>> {
    return this.http.get<ApiResponse<T>>(endpoint, { params });
  }
}
