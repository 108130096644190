/**
 * @description
 *
 * Flags to define the user's role in an organization, whether the user's role is 'admin' or 'user'.
 */
export enum UserRole {
  SUPERVISOR,
  COMPANY_ADMIN,
  COMPANY_USER,
  ASSISTANT,
  SUPERVISOR_SENDER_ACCOUNT
}
