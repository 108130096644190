export type ToastType = 'primary' | 'danger' | 'warning';

export class Toast {
  title: string;
  content: string;
  type: ToastType;
  id: string;
  delay: number;
  timeout: number;

  constructor(
    title: string,
    content: string,
    type: ToastType,
    delay: number,
    timeout: number = 0
  ) {
    this.title = title;
    this.content = content;
    this.type = type;
    this.id = Math.random().toString(36).substring(2);
    this.delay = delay;
    this.timeout = timeout;
  }
}
