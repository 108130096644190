import { Environment } from '@app/interfaces/environment';

export const environment: Environment = {
  name: 'production',
  production: true,
  api: {
    v1: 'https://mapi.bitesize.co/api/v1/',
    v2: 'https://mapi.bitesize.co/api/v2/',
    v3: 'https://mapi.bitesize.co/api/v3/'
  },
  pusher: {
    appKey: '3f7688628ad16635bb76'
  },
  sendServerUrl: 'https://send.bitesize.co/api/v3/',
  googleAnalytics: 'UA-123567527-2'
};
