<!-- Container -->
<div class="toasts-wrapper" aria-live="polite" aria-atomic="true">
  <!-- Toast -->
  <div class="toast bg-white" *ngFor="let toast of toasts; let index = index" @fadeInOut
    (mouseenter)="onMouseEnter(toast.timeout)" (mouseleave)="onMouseLeave(toast, index)" role="alert"
    aria-live="assertive" aria-atomic="true">
    <!-- Header -->
    <div class="toast-header {{ toast.type }}">
      <!-- Icon -->
      <fa-icon [icon]="faExclamation" [fixedWidth]="true" [classList]="'mr-2 text-' + toast.type"
        *ngIf="toast.type === 'danger'"></fa-icon>
      <fa-icon [icon]="faCheck" [fixedWidth]="true" [classList]="'mr-2 text-' + toast.type"
        *ngIf="toast.type === 'primary'"></fa-icon>
      <!-- Title -->
      <strong class="mr-auto text-{{ toast.type }}">{{ toast.title }}</strong>
      <!-- Close button -->
      <button class="btn btn-link text-dark btn-sm" (click)="close(toast, index)">
        <fa-icon [icon]="faTimes" [fixedWidth]="true"></fa-icon>
      </button>
    </div>
    <!-- Body -->
    <div class="toast-body" [innerHTML]="toast.content"></div>
  </div>
</div>