import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { WorkspaceService } from '../workspace/workspace.service';
import { HttpErrorResponseApi } from '@app/models/http-error-response-api/http-error-response-api';
import { ApiService } from '@app/services/api/api.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private authService: AuthService,
    private workspaceService: WorkspaceService
  ) {}

  /**
   * @param request The outgoing request to handle
   * @param next The next interceptor in the chain, or the backend if no interceptors in the chain.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    /**
     * @description
     *
     * Determines whether or not we should not send `bt_workspace` as query params
     */
    const removeWorkspace: boolean =
      request.params.has(ApiService.WORKSPACE_NOT_REQUIRED_PARAM) &&
      request.params.get(ApiService.WORKSPACE_NOT_REQUIRED_PARAM) === 'true';
    /**
     * @description
     *
     * Get token from cookies
     *
     * @see CookieService.get
     */
    const token: string = this.cookieService.get('token');
    /**
     * @description
     *
     * This variable is being used to update HTTP request
     */
    const update: {
      setHeaders?: {
        [name: string]: string | string[];
      };
      setParams?: {
        [param: string]: string;
      };
      params?: HttpParams;
    } = {};
    /**
     * If token exists
     */
    if (token) {
      /**
       * Set `Authorization` headers based on found token in cookies
       */
      update.setHeaders = {
        Authorization: `Bearer ${token}`
      };
      if (request.url.startsWith('http')) {
        /**
         * If current workspace exists and `bt_workspace` and `workspace_not_required`
         * doesn't exist in URL's params list, then add `bt_workspace` to URL params.
         */
        if (
          this.workspaceService.currentWorkspace &&
          !request.params.has('bt_workspace') &&
          !removeWorkspace
        ) {
          update.setParams = {
            bt_workspace: this.workspaceService.currentWorkspace.id.toString()
          };
        }
        if (removeWorkspace) {
          update.params = request.params.delete(
            ApiService.WORKSPACE_NOT_REQUIRED_PARAM
          );
        }
      }
      request = request.clone(update);
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse): Observable<never> => {
        if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error('An error occurred:', error.error.message);
        } else {
          // Auto logout if 401 response returned from api
          if (error.status === 401) {
            this.authService.unAuthUser();
          }
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            'Backend returned code:',
            error.status,
            'body was: ',
            error.error
          );
        }

        return throwError(new HttpErrorResponseApi(error as any));
      })
    );
  }
}
