import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@app/services/api/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserRole } from '@app/enums/user-role.enum';
import { User } from '@app/interfaces/user';
import { map } from 'rxjs/operators';
import { GetParams } from '@app/interfaces/get-params';
import { Utils } from '@app/basement/utils';
import * as FullStory from '@fullstory/browser';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * @description
   *
   * Authentication user subject which emits authenticated user's data information value whenever it is subscribed to.
   *
   * @see BehaviorSubject
   */
  private static userSubject: BehaviorSubject<User | null> =
    new BehaviorSubject<User | null>(null);

  /**
   * @description
   *
   * An observable snapshot data of {@link userSubject} value
   *
   * @see Observable
   */
  static userObservable: Observable<User> =
    UserService.userSubject.asObservable() as Observable<User>;

  /**
   * @description
   *
   * Store permissions
   */
  static permissions: string[] = [];

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {}

  /**
   * @description
   *
   * Set and update current authenticated user's data by updating {@link userSubject}'s value and local storage's
   * 'user' item
   *
   * @param data User data information
   */
  static set user(data: User) {
    UserService.permissions = data ? data.permissions : [];
    UserService.userSubject.next(data);
    localStorage.setItem('user', JSON.stringify(data));
  }

  /**
   * @returns Latest authenticated user's data information from local storage
   */
  static get user(): User {
    return localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user') as string)
      : null;
  }

  /**
   * @returns A boolean which indicates whether user's role is admin or not
   *
   * @see UserRole
   */
  static get isAdmin(): boolean {
    if (
      UserService.user.role == UserRole.COMPANY_ADMIN ||
      UserService.user.role == UserRole.SUPERVISOR
    ) {
      return true;
    } else {
      return false;
    }
  }
  static get isSuperAdmin(): boolean {
    return UserService.user.role === UserRole.SUPERVISOR;
  }

  /**
   * @returns A boolean which indicates whether user's role is assistant or not
   *
   * @see UserRole
   */
  static get isAssistant(): boolean {
    return UserService.user.role === UserRole.ASSISTANT;
  }

  /**
   * @description
   *
   * Retrieve authenticated user's data information
   *
   * @return An observable which contains current authenticated user's data information
   */
  retrieveUser(): Observable<User> {
    return this.http.get<User>(`${this.apiService.api.v1}auth/user/`).pipe(
      map((data: User): User => {
        UserService.user = data;
        const fsData = {
          displayName: UserService.user.full_name,
          email: UserService.user.email,
          org: UserService.user.org.name
        };
        FullStory.identify(UserService.user.id, fsData);
        return data;
      })
    );
  }

  /**
   * @description
   *
   * Get users list that their field are parameterized on `T` which is the interface of user's fields.
   * If an ID was given, then get single user information.
   *
   * @param params Fields that members should have
   * @param id User ID to get
   * @param notWorkspaceBased Indicates whether or not user data should be based on current space
   *
   * @returns An observable which contains a member
   */
  getUsers<T>(
    params: GetParams = {},
    id?: string,
    notWorkspaceBased?: boolean
  ): Observable<T> {
    let url = `${this.apiService.api.v2}users`;
    /**
     * If an ID was given, then get single user information.
     */
    if (id) {
      url = `${url}/${id}`;
    }
    return this.http.get<T>(url, {
      params: Utils.buildParams<GetParams>(params, notWorkspaceBased)
    });
  }

  patchUser(payload: Partial<User>, id: string): Observable<User> {
    return this.http
      .patch<User>(`${this.apiService.api.v1}auth/user/`, payload)
      .pipe(
        map((data: User): User => {
          const currentUser: User = UserService.user;
          Object.keys(data).forEach((key: string): void => {
            (currentUser as any)[key] = (data as any)[key];
          });
          UserService.user = currentUser;
          return data;
        })
      );
  }

  submitTwilioA2P10Dlc(payload: {[key: string]: string}): Observable<{ success: boolean; error: string; }> {
    const url = `${this.apiService.api.v3}users/twilio-a2p-10-dlc`;
    return this.http.post<{ success: boolean; error: string; }>(url, payload).pipe(
      map(
        (data: { success: boolean; error: string; }): { success: boolean; error: string; } => {
          return data
        }
      )
    )
  }

  getUserAccessibleOrgs(): Observable<{ id: string; name: string }[]> {
    const url = `${this.apiService.api.v3}users/user-orgs`;
    return this.http.get<{ orgs: { id: string; name: string }[] }>(url).pipe(
      map(
        (data: {
          orgs: { id: string; name: string }[];
        }): { id: string; name: string }[] => {
          return data.orgs;
        }
      )
    );
  }

  selectUserOrg(orgId: string): Observable<boolean> {
    const url = `${this.apiService.api.v3}users/select-user-org`;
    return this.http.post<{ message: string }>(url, { orgId: orgId }).pipe(
      map((data: { message: string }): boolean => {
        if (data.message === 'success') {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
