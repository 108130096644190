import { HttpParams } from '@angular/common/http';
import { ApiService } from '@app/services/api/api.service';

export class Utils {
  /**
   * @description
   *
   * Build query params based on given source object which its type is parameterized on `T`
   *
   * @param source Source to build {@link HttpParams} from it
   * @param notWorkspaceBased Indicates whether or not we should be remove workspace filter
   *
   * @returns HttpParams built based on given source
   */
  static buildParams<T>(source: T, notWorkspaceBased?: boolean): HttpParams {
    /**
     * @description
     *
     * An HTTP request/response body that represents serialized parameters,
     * per the MIME type `application/x-www-form-urlencoded`.
     *
     * @see HttpParams
     */
    let httpParams: HttpParams = new HttpParams();
    Object.keys(source as any).forEach((key: string): void => {
      /**
       * @description
       *
       * Value of the key
       */
      const value: string | number | boolean | Date = (source as any)[key];
      /**
       * If value actually had value, then append it to http params
       */
      if (typeof value !== 'undefined' && value !== null && value !== '') {
        httpParams = httpParams.append(key, value.toString());
      }
    });
    /**
     * Check if whether or not we should be remove workspace filter
     */
    if (notWorkspaceBased) {
      httpParams = httpParams.append(
        ApiService.WORKSPACE_NOT_REQUIRED_PARAM,
        'true'
      );
    }
    return httpParams;
  }

  /**
   * @description
   *
   * Insert given text at current caret position in the given input element
   *
   * @param inputElement Input element to insert to
   * @param text Text to insert
   * @param isCoded Indicates whether to wrap text into a hardcoded brackets or not
   */
  static insertIntoInput<T extends HTMLElement>(
    inputElement: T,
    text: string,
    isCoded?: boolean
  ): void {
    /**
     * Wrap given text into hardcoded brackets
     */
    if (isCoded) {
      text = `{{${text}}}`;
    }
    /**
     * Focus on element
     */
    setTimeout((): void => {
      inputElement.focus();
      /**
       * @description
       *
       * Executes 'insertText' command current selection, or the given range.
       */
      document.execCommand('insertText', false, text);
    });
  }
}
