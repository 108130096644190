import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { UserWorkspace } from '../../interfaces/user-workspace';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  /**
   * Workspace subject
   */
  private static workspaceSubject: BehaviorSubject<UserWorkspace | null> =
    new BehaviorSubject<UserWorkspace | null>(null);

  /**
   * Current workspace
   */
  workspace: Observable<UserWorkspace> =
    WorkspaceService.workspaceSubject.asObservable() as Observable<UserWorkspace>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cookieService: CookieService
  ) {}

  /**
   * Update current workspace
   *
   * @param workspace Workspace data
   */
  set currentWorkspace(workspace: UserWorkspace) {
    this.cookieService.set(
      'workspace',
      JSON.stringify(workspace),
      undefined,
      '/'
    );
    WorkspaceService.workspaceSubject.next(workspace);
  }

  /**
   * @return Current workspace
   */
  get currentWorkspace(): UserWorkspace {
    return this.cookieService.check('workspace')
      ? JSON.parse(this.cookieService.get('workspace'))
      : null;
  }

  /**
   * @description
   *
   * Get workspace ID from query params
   *
   * @returns Workspace ID
   */
  getParamWorkspace(): number {
    /**
     * @description
     *
     * Get current workspace id
     */
    let workspaceId: number = this.currentWorkspace.id;
    /**
     * If `bt_workspace` exists in query params, then re-set workspace ID.
     */
    if (this.activatedRoute.snapshot.queryParams['bt_workspace']) {
      workspaceId = +this.activatedRoute.snapshot.queryParams['bt_workspace'];
    }
    return workspaceId;
  }
}
