import { Injectable } from '@angular/core';
import { Toast } from '../../models/toast/toast';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  public toastSubject: BehaviorSubject<Toast | null> =
    new BehaviorSubject<Toast | null>(null);

  constructor() {}

  public primary(title: string, content: string, delay?: number): void {
    this.toastSubject.next(
      new Toast(title, content, 'primary', delay ? delay : 5000)
    );
  }

  public danger(title: string, content: string, delay?: number): void {
    this.toastSubject.next(
      new Toast(title, content, 'danger', delay ? delay : 5000)
    );
  }

  public warning(title: string, content: string, delay?: number): void {
    this.toastSubject.next(
      new Toast(title, content, 'warning', delay ? delay : 5000)
    );
  }
}
